<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row>
      <p><span>{{ $t('title.ordernumber') }}:</span><span>{{ detailList.orderCode }}</span></p>
      <p><span>{{ $t('page.createTime') }}:</span><span>{{ detailList.returnCreateTime }}</span></p>
      <p><span>{{ $t('topic.BuyerID') }}:</span><span>{{ detailList.buyerUsername }}</span></p>

      <el-table
        id="refund"
        :data="detailList.detailList"
        :header-cell-style="{background:'#eee'}"
        default-expand-all
        style="width: 100%"
      >
        <el-table-column
          :label="$t('title.Refundinformation')"
        >
          <template slot-scope="props">
            <div style="display:flex">
              <el-image
                :src="props.row.mainImage"
                class="mr-3"
                style="width: 35px; height: 35px; vertical-align: middle"
              />
              <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
            </div></template>
        </el-table-column>
        <el-table-column
          :label="$t('topic.Numberofrefunds')"
          prop="returnNumber"
          width="80"
        />
        <el-table-column
          :label="$t('topic.reasonforreturn')"
          width="160"
        >
          <template slot-scope="props">
            <el-select
              v-model="props.row.returnReasonCodeCN"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              disabled
              filterable
            >
              <el-option
                v-for="item in returnReasonOptions"
                :key="item.returnReasonCodeEN"
                :label="language === 'en-US' ? item.returnReasonCodeEN : item.returnReasonCodeCN"
                :value="item.returnReasonCodeCN"
              />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column type="expand">
          <template slot-scope="props">
            <ul class="myTable">
              <li>
                <span />
                <span>{{ $t('title.orderamount') }}</span>
                <span>{{ $t('topic.Orderrefund') }}</span>
                <span />
              </li>
              <li v-if="props.row.itemPrice">
                <span>{{ $t('topic.Productfee') }}</span>
                <span>{{ props.row.itemPrice }}</span>
                <span style="display:flex;align-items: center;"><span> $ </span> <el-input
                  v-model="props.row.myItemPrice"
                  :disabled="!showRow"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @input="changeInput(props.$index)"
                /></span>
                <span style="display:flex;align-items: center;justify-content: center;">
                  <!-- <el-checkbox
                    :v-model="props.row.shippingPriceCheckbox"
                    @change="checked=>changeNumber(props.$index, checked)"
                  /> -->
                  <p style="display:block;width:75px"><el-button type="text" @click="showRowClick()">{{ showRow ? $t('title.Bypercentage') : $t('title.Customizedamount') }}</el-button></p>
                  <el-input
                    v-model.number="props.row.mypromotion"
                    :disabled="showRow"
                    class="input-style"
                    @input="changeInput(props.$index)"
                  /><i> % </i></span>
              </li>
              <li v-if="props.row.itemTax">
                <span>{{ $t('topic.Producttaxes') }}</span>
                <span>{{ props.row.itemTax }}</span>
                <span style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.myItemTax" disabled oninput="value=value.replace(/[^0-9.]/g,'')" @change="changeNumber(props.$index)" /></span>
                <span />
              </li>
              <li v-if="props.row.shippingPrice">
                <span>{{ $t('topic.Productexpeditedshipping') }}</span>
                <span>{{ props.row.shippingPrice }}</span>
                <span style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.myShippingPrice" disabled oninput="value=value.replace(/[^0-9.]/g,'')" @change="changeNumber(props.$index)" /></span>
                <span />
              </li>
              <li v-if="props.row.shippingTax">
                <span>{{ $t('topic.Expeditedshippingtax') }}</span>
                <span>{{ props.row.shippingTax }}</span>
                <span style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.myShippingTax" disabled oninput="value=value.replace(/[^0-9.]/g,'')" @change="changeNumber(props.$index)" /></span>
                <span />
              </li>
              <li>
                <span style="font-weight: 600;">{{ $t('title.Totalrefund') }}</span>
                <span style="font-weight: 600;">{{ props.row.myrefundAmount }}</span>
                <span style="font-weight: 600;">{{ props.row.refundAmount }}</span>
                <span />
              </li>
            </ul>
            <!-- <ul class="myTable">
              <li>
                <span>额外退费/收费</span>
                <span />
                <span>金额</span>
                <span />
              </li>
              <li v-if="props.row.itemPrice">
                <span>额外退费</span>
                <span />
                <span style="display:flex;align-items: center;">
                  <p style="display:block;width:80px">退费</p>
                  <span> $ </span>
                  <el-input v-model="props.row.otherRefund" oninput="value=value.replace(/[^0-9.]\d{1,2}/g,'')" @change="changeNumber(props.$index)" />
                </span>
                <span />
              </li>
              <li>
                <span style="font-weight: 600;">总计</span>
                <span style="font-weight: 600;">{{ props.row.myrefundAmount }}</span>
                <span style="font-weight: 600;">{{ props.row.refundAmount }}</span>
                <span />
              </li>
            </ul> -->
            <p style="display: flex;align-items: center;"><span style="display: inline-block;width: 81px;">{{ $t('title.Buyerpessage') }}</span>
              <el-input
                v-model="props.row.sellComments"
                :placeholder="$t('page.inputPlaceholder')"
                :rows="2"
                style="width: 500px;"
                type="textarea"
              /></p>
            <p style="display: flex;align-items: center;"><span style="display: inline-block;width: 81px;">{{ $t('title.stytemNote') }}</span>
              <el-input
                v-model="props.row.remark"
                :placeholder="$t('page.inputPlaceholder')"
                :rows="2"
                style="width: 500px;"
                type="textarea"
              /></p>
          </template>

        </el-table-column>

      </el-table>

    </el-row>
    <el-row :span="24" justify="end" style="margin-top: 20px" type="flex">
      <el-col :span="3">
        <el-button @click="goBack">{{ $t('title.back') }}</el-button>
      </el-col>
      <el-col :span="6">
        <el-button :loading="refundLoding" type="primary" @click="saveRefund">{{ $t('page.sure') }}</el-button>
      </el-col>
    </el-row>

  </div>

</template>
<script>
import Cookies from 'js-cookie'
import { getAllReasonCode, getDetailReturnsInfoById, saveReturnInfos, updateStatus } from '@/api/omsorder'
import mixin from '@/mixin/oms-mixin'

export default {
  mixins: [mixin],
  data() {
    return {
      language: '',
      checked: false,
      refundLoding: false,
      detailList: {},
      showRow: false, // 切换显示影藏按钮
      returnReasonOptions: []
    }
  },
  mounted() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erplang || lang
    this._getDetailReturnsInfoById()
    this.initDatas()
  },
  methods: {
    // 点击展开控制按钮的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    initDatas() {
      this.detailList = sessionStorage.getItem('indatas') ? JSON.parse(sessionStorage.getItem('indatas')) : []
      console.log(this.detailList)
      if (this.detailList.length !== 0) {
        this.detailList.detailList.map(e => {
          e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
          e.returnReasonCodeCN = e.returnReasonCode
          e.myItemPrice = e.itemPrice
          e.myItemTax = e.itemTax ? e.itemTax : 0
          e.myShippingPrice = e.shippingPrice ? e.shippingPrice : 0
          e.myShippingTax = e.shippingTax ? e.shippingTax : 0
          e.myrefundAmount = (parseFloat(e.itemPrice) + parseFloat(e.itemTax) + parseFloat(e.shippingPrice) + parseFloat(e.shippingTax)).toFixed(2)
          e.myotherRefund = e.otherRefund
          e.mypromotion = 100
          e.refundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax) + parseFloat(e.myShippingPrice) + parseFloat(e.myShippingTax)).toFixed(2)
          if (e.refundType === 1 || e.refundType === '1') {
            this.showRow = true
          }
        })

        this.detailList.orderCode = this.detailList.returnsInfo.orderCode
        this.detailList.buyerUsername = this.detailList.returnsInfo.buyerUsername
      }
    },
    // 通过id 查询对应数据
    async _getDetailReturnsInfoById(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await getDetailReturnsInfoById(id)
        this.detailList = datas
        datas.detailList.map(e => {
          e.myItemPrice = e.itemPrice
          e.myItemTax = e.itemTax ? e.itemTax : 0
          e.myShippingPrice = e.shippingPrice ? e.shippingPrice : 0
          e.myShippingTax = e.shippingTax ? e.shippingTax : 0
          e.myrefundAmount = (parseFloat(e.itemPrice) + parseFloat(e.itemTax) + parseFloat(e.shippingPrice) + parseFloat(e.shippingTax)).toFixed(2)
          e.myotherRefund = e.otherRefund
          e.mypromotion = 100
          e.refundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax) + parseFloat(e.myShippingPrice) + parseFloat(e.myShippingTax)).toFixed(2)
          if (e.refundType === 1 || e.refundType === '1') {
            this.showRow = true
          }
        })
      }
      this._getAllReasonCode(this.detailList.platFormName)
    },
    // 获退款退货原因
    async _getAllReasonCode(platform) {
      const { datas } = await getAllReasonCode(platform)
      this.returnReasonOptions = datas
    },
    saveRefund() {
      this.$confirm(this.$t('tips.refundAmountTips'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(() => {
        const list = []
        this.detailList.detailList.map(e => {
          e.itemPrice = e.myItemPrice
          e.itemTax = e.myItemTax
          e.shippingPrice = e.myShippingPrice
          e.shippingTax = e.myShippingTax
          e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
        })
        if (+this.$route.query.repostType === 1) { // 重推
          this.detailList.operatorId = 5

          list.push(this.detailList)
          this._updateStatus(list)
        } else if (+this.$route.query.repostType === 2) { // 手工创建保存后 点发布按钮校验 如果选中是仅退款(全额 或 部分退款)
          const data = {}
          const { rmaId, id, remark, returnDetailType, sellComments, orderCode, returnType, siteCode, shopCode, platFormName, buyerUsername, shipperAddress } = this.detailList
          data.detailList = this.detailList.detailList
          data.imageList = this.detailList.imageList
          data.returnsInfo = { rmaId, id, remark, returnDetailType, orderCode, siteCode, shopCode, returnType, sellComments, platFormName, buyerUsername }
          data.returnsTrack = { shipperAddress, orderCode }
          data.operating = 1
          this._saveReturnInfos(data)
        } else if (+this.$route.query.repostType === 3) { // 手工创建直接发布  如果选中是仅退款(全额 或 部分退款)
          this._saveReturnInfos(this.detailList)
        } else if (+this.$route.query.repostType === 5) {
          this.detailList.operatorId = 0
          list.push(this.detailList)
          this._updateStatus(list)
        } else { // 系统抓取的退款
          this.detailList.operatorId = 3
          list.push(this.detailList)
          this._updateStatus(list)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('title.Cancelleds')
        })
      })
    },
    // 更新状态
    async _updateStatus(data) {
      try {
        this.refundLoding = true
        const { code, msg } = await updateStatus(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.refundDialogVisible = false
          this.ShipDialogVisible = false
          this.$router.push({
            path: '../../walmart-return-manage',
            append: true,
            query: { ...this.userInfo }
          })
        }
      } finally {
        this.refundLoding = false
      }
    },
    // 保存或者发布确认
    async _saveReturnInfos(data) {
      try {
        this.refundLoding = true
        const { code, msg } = await saveReturnInfos(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../../walmart-return-manage',
            append: true,
            query: { ...this.userInfo }
          })
        }
      } finally {
        this.refundLoding = false
      }
    },
    goBack() {
      this.$router.push({
        path: '../../walmart-return-manage',
        append: true,
        query: { ...this.userInfo }
      })
    },
    changeInput(index, e) {
      var data = this.detailList.detailList[index]
      if (e !== undefined) {
        data.shippingPriceCheckbox = e
      }
      if (parseFloat(data.myItemPrice) > parseFloat(data.itemPrice) || data.myItemPrice === '') {
        this.$alert(this.$t('tips.ThanOriginal'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure')
        }).then(() => {
          data.myItemPrice = data.itemPrice
          data.refundAmount = (parseFloat(data.myItemPrice) + parseFloat(data.myItemTax)).toFixed(2)
          this.$set(this.detailList.detailList, index, data)
        })
      }
      if (this.showRow === false) {
        // 判断按比例分输入框金额大于等于100  意思为全额退税费也退  else 反之小于100意思为部分退  税费则为0
        if (data.mypromotion >= 100) {
          data.mypromotion = 100
          data.myItemPrice = parseFloat(data.itemPrice) * (parseFloat(data.mypromotion) / 100)
          data.myItemPrice = Number(data.myItemPrice.toString().match(/^\d+(?:\.\d{0,2})?/))

          data.myItemTax = parseFloat(data.itemTax) * (parseFloat(data.mypromotion) / 100)
          data.myItemTax = Number(data.myItemTax.toString().match(/^\d+(?:\.\d{0,2})?/))

          data.myShippingPrice = parseFloat(data.shippingPrice) * (parseFloat(data.mypromotion) / 100)
          data.myShippingPrice = Number(data.myShippingPrice.toString().match(/^\d+(?:\.\d{0,2})?/))

          data.myShippingTax = parseFloat(data.shippingTax) * (parseFloat(data.mypromotion) / 100)
          data.myShippingTax = Number(data.myShippingTax.toString().match(/^\d+(?:\.\d{0,2})?/))
        } else {
          data.myItemPrice = parseFloat(data.itemPrice) * (parseFloat(data.mypromotion) / 100)
          data.myItemPrice = Number(data.myItemPrice.toString().match(/^\d+(?:\.\d{0,2})?/))

          data.myItemTax = parseFloat(data.itemTax) * (parseFloat(data.mypromotion) / 100)
          data.myItemTax = Number(data.myItemTax.toString().match(/^\d+(?:\.\d{0,2})?/))

          data.myShippingPrice = parseFloat(data.shippingPrice) * (parseFloat(data.mypromotion) / 100)
          data.myShippingPrice = Number(data.myShippingPrice.toString().match(/^\d+(?:\.\d{0,2})?/))

          data.myShippingTax = parseFloat(data.shippingTax) * (parseFloat(data.mypromotion) / 100)
          data.myShippingTax = Number(data.myShippingTax.toString().match(/^\d+(?:\.\d{0,2})?/))
        }
        if (data.mypromotion <= 0 || data.mypromotion === '') {
          this.$alert(this.$t('tips.GreaterThan'), this.$t('page.Prompt'), {
            confirmButtonText: this.$t('page.sure')
          }).then(() => {
            data.mypromotion = 100
            data.myItemPrice = parseFloat(data.itemPrice) * (parseFloat(data.mypromotion) / 100)
            data.myItemPrice = Number(data.myItemPrice.toString().match(/^\d+(?:\.\d{0,2})?/))

            data.myItemTax = parseFloat(data.itemTax) * (parseFloat(data.mypromotion) / 100)
            data.myItemTax = Number(data.myItemTax.toString().match(/^\d+(?:\.\d{0,2})?/))

            data.myShippingPrice = parseFloat(data.shippingPrice) * (parseFloat(data.mypromotion) / 100)
            data.myShippingPrice = Number(data.myShippingPrice.toString().match(/^\d+(?:\.\d{0,2})?/))

            data.myShippingTax = parseFloat(data.shippingTax) * (parseFloat(data.mypromotion) / 100)
            data.myShippingTax = Number(data.myShippingTax.toString().match(/^\d+(?:\.\d{0,2})?/))
            // data.myItemTax = data.itemTax
            // data.myShippingPrice = Number(data.myShippingPrice.toString().match(/^\d+(?:\.\d{0,2})?/))
            // data.myItemPrice = Number(data.myItemPrice.toString().match(/^\d+(?:\.\d{0,2})?/))
            this.$set(this.detailList.detailList, index, data)
          })
        }
      } else {
        data.myItemTax = 0
        data.myShippingPrice = 0
        data.myShippingTax = 0
      }
      // 按比例分配大于100%时  将输入框得值置为100%
      if (parseFloat(data.mypromotion) > 100) {
        this.$alert(this.$t('tips.Lesser'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure')
        }).then(() => {
          data.mypromotion = 100
          this.$set(this.detailList.detailList, index, data)
        })
      }
      data.refundAmount = (parseFloat(data.myItemPrice) + parseFloat(data.myItemTax) + parseFloat(data.myShippingTax) + parseFloat(data.myShippingPrice)).toFixed(2)
      this.$set(this.detailList.detailList, index, data)
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
#refund /deep/ .el-table__expand-column{
  pointer-events: none;
}
#refund /deep/ .el-table__expand-column .el-icon{
  visibility:hidden;
}
.myTable {
  width: 100%;
  border: 1px solid #dfe6ec;
  padding: 0;
  text-align: center;
}
.myTable li {
  width: 100%;
  display: flex;
  list-style: none;
  padding: 5px 0;
  align-items: center;
}
.myTable li:nth-of-type(1) {
  border-bottom: 1px solid #dfe6ec;
  background: #eee;
}
.myTable li:nth-of-type(n-1) {
  border-bottom: 1px solid #dfe6ec;
}
.myTable li  span {
  flex: 1;
}
.input-style{
  width: 90px;
}
#refund {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
}
</style>
